/* eslint-disable */

import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import { AiFillEdit } from 'react-icons/ai';
import { useSelector, useDispatch } from 'react-redux';
import fetchOrders from 'src/functions/orders/fetchOrders';
import { useNavigate } from 'react-router-dom';

const SingleOrder = () => {
  const [showEdit, setShowEdit] = useState(false);
  const [status, setStatus] = useState('');
  const { orders } = useSelector((state) => state.orders);
  const user = useSelector((state) => state.user.users);
  const navigate = useNavigate();
  // get id from params
  const { id } = useParams();
  const [data, setData] = useState({});
  const [loading, setLoading] = useState(true);

  dayjs.extend(relativeTime);

  const [orderedAt, setOrderedAt] = useState();

  function getSingleOrder() {
    // filter order
    const order = orders.filter((order) => order._id === id);
    if (order.length === 0) {
      navigate('/dashboard/orders');
      return;
    }
    setData(order[0]);
    setLoading(false);
    setOrderedAt(dayjs(order[0].createdAt).fromNow());
  }

  useEffect(() => {
    getSingleOrder();
  }, [user]);

  function gotoNext(url) {
    window.open(url, '_blank');
  }

  function saveStatus() {
    const token = user?.token;
    const url = `${process.env.REACT_APP_URL}/api/orders/${id}/status`;

    const data = {
      status: status,
    };

    if (!!status) {
      setLoading(true);
      fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((data) => {
          setShowEdit(false);
          getSingleOrder();
          setLoading(false);
        })
        .catch((error) => {
          console.error('Error:', error);
          setLoading(false);
        });
    } else {
      setShowEdit(false);
    }
  }

  if (loading) {
    return (
      <div className="p-2 pl-10">
        <div className="flex">
          <nav className="text-gray-500">
            <ol className="list-none flex items-center space-x-2">
              <li>
                <Link to="/dashboard/orders" className="hover:text-gray-700">
                  Orders
                </Link>
              </li>
              <li>/</li>
              <li className="text-gray-700">Loading...</li>
            </ol>
          </nav>
        </div>
        <h1 className="text-2xl font-bold mb-4">Order Details</h1>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <p className="text-lg font-semibold">Name:</p>
            <p className="text-gray-700">Loading...</p>
            <p className="text-lg font-semibold">Phone:</p>
            <p className="text-gray-700">Loading...</p>
            <p className="text-lg font-semibold">City:</p>
            <p className="text-gray-700">Loading...</p>
            <p className="text-lg font-semibold">Shipping Address:</p>
            <p className="text-gray-700">Loading...</p>
            <p className="text-lg font-semibold">Shipping Charge:</p>
            <p className="text-gray-700">Loading...</p>
            <p className="text-lg font-semibold">Additional Info:</p>
            <p className="text-gray-700">Loading...</p>
          </div>
          <div>
            <p className="text-lg font-semibold">Total Price:</p>
            <p className="text-gray-700">Loading...</p>
            <p className="text-lg font-semibold">Payment Method:</p>
            <p className="text-gray-700">Loading...</p>
            <p className="text-lg font-semibold">Status:</p>
            <p className="text-gray-700">Loading...</p>
            <p className="text-lg font-semibold">Created At:</p>
            <p className="text-gray-700">Loading...</p>
            <p className="text-lg font-semibold">Ordered At:</p>
            <p className="text-gray-700">Loading...</p>
          </div>
        </div>
        <div className="mt-4">
          <h2 className="text-xl font-semibold mb-2">Order Items</h2>
          <p className="text-gray-700">Loading...</p>
        </div>

        <h2 className="text-xl font-semibold mb-2">Payment Info</h2>
        <p className="text-gray-700">Loading...</p>
      </div>
    );
  }

  // Reusable component for displaying information items
  const InfoItem = ({ title, content }) => (
    <div className="mb-4">
      <p className="text-lg font-semibold">{title}:</p>
      <p className="text-gray-700">{content}</p>
    </div>
  );

  return (
    <div className="p-4">
      {/* Breadcrumbs */}
      <nav className="text-gray-500 mb-4">
        <ol className="list-none flex items-center space-x-2">
          <li>
            <Link to="/dashboard/orders" className="hover:text-gray-700">
              Orders
            </Link>
          </li>
          <li>/</li>
          <li className="text-gray-700">{data.name}</li>
        </ol>
      </nav>

      {/* Order Details Header */}
      <h1 className="text-3xl font-bold mb-4">Order Details</h1>

      {data.additionalInfo.includes('Customise') && (
        <div className="flex flex-col justify-start gap-5 bg-purple-600 mt-4 p-3 text-white mb-5">
          Note: This is a custom case order
        </div>
      )}

      {/* Order Information Grid */}
      <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-8">
        <div>
          <InfoItem title="Name" content={data.name} />
          <InfoItem title="Phone" content={data.phone} />
          <InfoItem title="City" content={data.city} />
          <InfoItem title="Shipping Address" content={data.shippingAddress} />
          <InfoItem title="Shipping Charge" content={data.shippingCharge || data?.priceSummary?.deliveryCharge} />
        </div>
        <div>
          <InfoItem title="Total Price" content={data.totalPrice ? data.totalPrice : data.priceSummary?.grandTotal} />
          <InfoItem title="Payment Method" content={data.paymentMethod} />
          {!!data?.priceSummary?.promoCode && <InfoItem title="Coupin Code" content={data?.priceSummary?.promoCode} />}
          {!showEdit && (
            <div className="flex items-center gap-2 flex-row">
              <InfoItem className="p-2 bg-blue-gray-400 rounded-sm" title="Status" content={data.status} />
              <div
                onClick={() => setShowEdit(true)}
                className="flex h-8 w-8 justify-center items-center bg-blue-gray-200 hover:bg-blue-gray-100 transition-all cursor-pointer rounded-full"
              >
                <AiFillEdit />
              </div>
            </div>
          )}
          {showEdit && (
            <div className="flex flex-col gap-3 pb-5">
              <p className="text-md font-bold" htmlFor="link">
                Status
              </p>
              {/* make 3 divs with focused background colored blue, */}
              <input
                onChange={(e) => setStatus(e.target.value)}
                defaultValue={data?.status}
                className="border-2 border-blue-500 rounded-md p-2"
                type="text"
                name="link"
                id="link"
                placeholder="Enter Status"
              />

              <div className="flex gap-3">
                <button
                  onClick={() => saveStatus()}
                  className="bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center font-bold py-2 px-4 "
                >
                  Save
                </button>
                {/* cancel button */}
                <button
                  onClick={() => setShowEdit(false)}
                  className="bg-red-500 hover:bg-red-700 text-white flex justify-center items-center font-bold py-2 px-4 "
                >
                  Cancel
                </button>
              </div>
            </div>
          )}

          <InfoItem title="Ordered At" content={orderedAt} />
          <InfoItem title="Additional Info" content={data.additionalInfo} />
        </div>
      </div>

      {/* Order Items */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Order Items</h2>
        {data?.orderItems?.map((item, index) => (
          <div key={index} className="flex items-center mb-4 gap-4">
           <div class="flex flex-col gap-2">
             <img src={item.image} alt={item.name} className="h-20 w-20 rounded-md object-cover " />
                   {data.additionalInfo.includes('Customise') && (
                     <div className="flex flex-col justify-start gap-5 ">
                       <button className='bg-blue-500 hover:bg-blue-700 text-white flex justify-center items-center text-sm py-2 px-4' onClick={() => gotoNext(item.image)}>Download</button>
                     </div>
                   )}
           </div>

            <div>
              <p className="text-lg font-semibold">{item.name}</p>
              <p className="text-gray-700">{`Price: ${item.price}, Qty: ${item.qty}`}</p>
              {item?.variant && <p className="text-gray-700">{`Variant: ${item?.variant}`}</p>}
            </div>
          </div>
        ))}
      </div>

      {/* Payment Info */}
      <div className="mb-8">
        <h2 className="text-xl font-semibold mb-2">Payment Info</h2>
        <a href={data.paymentImage} target="_blank" rel="noreferrer">
          <img src={data.paymentImage} alt="Payment" className="mt-4 h-20" />
        </a>
      </div>

      {/* if custom case */}
      
    </div>
  );
};

export default SingleOrder;
